import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import App from './pages/App';
import reducers from './reducers'

import { createMiddleware } from 'redux-beacon';
import { LOCATION_CHANGE } from 'connected-react-router'
import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics';


const ga = GoogleAnalytics();
const eventsMap = {
    [LOCATION_CHANGE]: trackPageView(action => ({
                page: action.payload.pathname,
                title: "/search"
    }))
};

const middleware = createMiddleware(eventsMap, ga)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__ || compose


const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk, middleware))
)


ReactDOM.render(

    <Provider store={store}>
        <div>
            <App />
        </div>
    </Provider>
    , document.getElementById('root'));


