export const GET_PATHS = 'GET_PATHS'
export const GET_COMMON = 'GET_COMMON'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_LOADING_MORE_STATUS = 'SET_LOADING_MORE_STATUS'
export const SET_LOADING_PATH_DETAIL_STATUS = 'SET_LOADING_PATH_DETAIL_STATUS'
export const GET_AUTO_COMPLETE_SUGGESTIONS = 'GET_AUTO_COMPLETE_SUGGESTIONS'
export const CLEAR_AUTO_COMPLETE_SUGGESTIONS = 'CLEAR_AUTO_COMPLETE_SUGGESTIONS'
export const FETCH_RANDOM_LINK_REQUEST = 'FETCH_RANDOM_LINK_REQUEST'
export const FETCH_RANDOM_LINK_SUCCESS = 'FETCH_RANDOM_LINK_SUCCESS'
export const FETCH_RANDOM_LINK_FAILURE = 'FETCH_RANDOM_LINK_FAILURE'
export const GET_RANDOM_LINK = 'GET_RANDOM_LINK'
export const GET_PATH_DETAIL = 'GET_PATH_DETAIL'
export const SET_START_LINK = 'SET_START_LINK'
export const SET_END_LINK = 'SET_END_LINK'
export const SET_DIRECTION = 'SET_DIRECTION'
export const GET_EXHAUST = 'GET_EXHAUST'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'


