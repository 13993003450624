import React, { Component } from 'react';


class Header extends Component {


    render() {
        return (
            <div>

            </div>
        )
    }


}


export default Header;



