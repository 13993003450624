import React, { Component } from 'react';

import { Row, Col } from 'antd';




class Footer extends Component {


    render() {
        return (

            <footer style={{
                position:'absolute',
                
                bottom:0,
                left:0,
                right:0,
                marginTop:40,
                backgroundColor:'white',
                alignItems: 'center'
                }}>
                <div>
                    <Row>
                        <Col span={8}>
                            {/* <Link to="/about">What is this?</Link> */}
                        </Col>
                        <Col span={8}>
                            
                        </Col>
                            {/* <Link to="/contact">Who are you?</Link> */}
                        <Col span={8}>
                            
                        </Col>
                    </Row>
                </div>
            </footer>

        )
    }


}


export default Footer;



