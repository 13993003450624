import { combineReducers } from 'redux';
//import { reducer as formReducer } from 'redux-form';
import * as TYPES from '../app/types'

const pathsReducer = (state=[],action) => {
    //console.log('in query results reducer'+ action);
    //console.log(action);
    switch(action.type){
        case TYPES.GET_PATHS:
            return action.payload;
        default:
            return state;
    }
}

const pathDetailReducer = (state=[], action) => {
    switch(action.type){
        case TYPES.GET_PATH_DETAIL:
            return action.payload;
        default:
            return state;
    }
}

const commonReducer = (state=[], action) => {
    switch(action.type){
        case TYPES.GET_COMMON:
            return action.payload;
        default:
            return state;
    }
}

const exhaustReducer = (state='', action) => {
    
    switch(action.type){
        case TYPES.GET_EXHAUST:
            return action.payload;
        default:
            return state;

    }
}



const loadingStatusReducer = (state=false,action) => {
    
    switch(action.type){
        case TYPES.SET_LOADING_STATUS:
            return action.payload;
        default:
            return state;
    }
}


const loadingMoreStatusReducer = (state=false,action) => {
    
    switch(action.type){
        case TYPES.SET_LOADING_MORE_STATUS:
            return action.payload;
        default:
            return state;
    }
}

const loadingPathDetailStatusReducer = (state=false,action) => {
    
    switch(action.type){
        case TYPES.SET_LOADING_PATH_DETAIL_STATUS:
            return action.payload;
        default:
            return state;
    }
}



const autocompleteSuggestionsReducer = (state=[],action) => {

    switch(action.type){
        case TYPES.GET_AUTO_COMPLETE_SUGGESTIONS:
            return action.payload;
        case TYPES.CLEAR_AUTO_COMPLETE_SUGGESTIONS:
            return [];
        default:
            return state;
    }
}

const directionReducer = (state='middle', action) => {
    switch(action.type){
        case TYPES.SET_DIRECTION:
            return action.payload;
        default:
            return state
    }
}

const setStartLinkReducer = (state='',action) => {
    
    switch(action.type){
        case TYPES.SET_START_LINK:
            return action.payload;
        default:
            return state;

    }
}

const setEndLinkReducer = (state='',action) => {
    switch(action.type){
        case TYPES.SET_END_LINK:
            return action.payload;
        default:
            return state;
    }
}

const data = {
        startlink: {
        title: "",
        url: "",
    },
    endlink: {
        title: "",
        url: ""
    }
}

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

const randomLinkReducer = (state = initialState, action) => {
    switch(action.type){
        case TYPES.FETCH_RANDOM_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case TYPES.FETCH_RANDOM_LINK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case TYPES.FETCH_RANDOM_LINK_FAILURE:
            return {
                ...state,
                isLoading:false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const randomLinkReducertwo = (state={
    startlink: {
        title: "",
        url: "",
    },
    endlink: {
        title: "",
        url: ""
    }
},action) => {
    switch(action.type){
        case TYPES.GET_RANDOM_LINK:
            return action.payload;
        default:
            return state;
    }
}

const errorMessageReducer = (state='', action) => {
    switch(action.type){
        case TYPES.SET_ERROR_MESSAGE:
            return action.payload;
        default:
            return state;
    }
}






export default combineReducers({
    paths: pathsReducer,
    common: commonReducer,
    direction: directionReducer,
    pathDetail: pathDetailReducer,
    loadingStatus: loadingStatusReducer,
    loadingMoreStatus: loadingMoreStatusReducer,
    loadingPathDetailStatus: loadingPathDetailStatusReducer,
    exhaust: exhaustReducer,
    autocompleteSuggestions: autocompleteSuggestionsReducer,
    randomLink: randomLinkReducer,
    startLink: setStartLinkReducer,
    endLink: setEndLinkReducer,
    errorMessage: errorMessageReducer


});
