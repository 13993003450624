import React from 'react'
import Loadable from 'react-loadable'
import { Spin } from 'antd';


const Loading = (props) => {
    if (props.pastDelay) {
        return (
            <div>
                <Spin style={{marginTop:"30%"}}/>
            </div>
        )

    }
    return (
        <div>
            <Spin style={{marginTop:"30%",height:40}}/>
        </div>
    )
}

export default function CustomLoadable(opts) {
    return Loadable(Object.assign({
        loading: Loading,
        delay: 10000,
    }, opts))
}