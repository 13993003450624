export const HOME = '/home';
export const CONTACT = '/contact';
export const SEARCH = '/search';
export const PATHDETAIL = '/pathdetail';




//export const API_ENDPOINT = 'http://127.0.1.1:5000/'    
//export const API_ENDPOINT = 'http://206.81.28.46:5000/'    
export const API_ENDPOINT = 'https://www.pankajahuja.xyz/'




//export const API_ENDPOINT = 'https://www.4cuf.com/' 
export const QUERY = 'query'
export const API_QUERY_URL = API_ENDPOINT+QUERY

export const AUTOCOMPLETE = 'autocomplete'
export const API_AUTOCOMPLETE_URL = API_ENDPOINT+AUTOCOMPLETE

export const RANDOM_LINK = 'random_link'
export const API_RANDOM_LINK = API_ENDPOINT + RANDOM_LINK

export const PATH_DETAIL = 'path_detail'
export const API_PATH_DETAIL = API_ENDPOINT + PATH_DETAIL



