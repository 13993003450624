import * as API from '../app/apis';
import * as TYPES from '../app/types';


export const getQueryResults = (startLink, endLink, direction, common, exhaust) => async dispatch => {
    const response = await API.getQueryResults(startLink, endLink, direction, common, exhaust);
    
    if (response) {
        if (response.status === 200) {
            
            if (response.data.error === "INVALIDQUERY") {

                dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: response.data.error })
                //dispatch({ type: TYPES.SET_LOADING_STATUS, payload: false })

            }
            else {
                const exhaust = (response.data.exhausted === 'false') ? false : true
                //console.log('response is 200',direction)
                
                dispatch({ type: TYPES.SET_START_LINK, payload: startLink })
                dispatch({ type: TYPES.SET_END_LINK, payload: endLink })
                dispatch({ type: TYPES.SET_DIRECTION, payload: direction })
                dispatch({ type: TYPES.GET_COMMON, payload: response.data.common })
                dispatch({ type: TYPES.GET_PATHS, payload: response.data.paths })
                dispatch({ type: TYPES.GET_EXHAUST, payload: exhaust })
                dispatch({ type: TYPES.SET_LOADING_STATUS, payload: false })
                dispatch({ type: TYPES.SET_LOADING_MORE_STATUS, payload: false })
                // dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: 'hi we are here' })

            }
        }
        else if (response.status === 204) {
            //console.log('204')

            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: response.statusText })

            dispatch({ type: TYPES.GET_EXHAUST, payload: true })
        }
        else if (response.status === 400) {
            //console.log('400')
            
            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: response.data.message })
            dispatch({ type: TYPES.SET_LOADING_STATUS, payload: false })
            dispatch({ type: TYPES.SET_LOADING_MORE_STATUS, payload: false })


        }
        else {
            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: response.statusText })
            dispatch({ type: TYPES.SET_LOADING_STATUS, payload: false })
            dispatch({ type: TYPES.SET_LOADING_MORE_STATUS, payload: false })
        }
    }
    else {
        dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: 'Something went wrong while querying, please try refreshing the page' })
        dispatch({ type: TYPES.SET_LOADING_STATUS, payload: false })
        dispatch({ type: TYPES.SET_LOADING_MORE_STATUS, payload: false })

        
    }
}

export const clearPathsAndForbidden = () => dispatch => {
    dispatch({ type: TYPES.GET_PATHS, payload: [] })
    dispatch({ type: TYPES.GET_COMMON, payload: [] })
}

export const getPathDetail = (path) => async dispatch => {

    const response = await API.getPathDetail(path);
    if (response){
        if (response.status === 200){
            dispatch({ type: TYPES.GET_PATH_DETAIL, payload: response.data })
        }
        else {
            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: "Ran into a hiccup generating the details." })
            dispatch({ type: TYPES.SET_LOADING_PATH_DETAIL_STATUS, payload: false })
        }
    }
    else{
        dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: 'Something went wrong with loading the path. Weird ... try refreshing the page' })
        dispatch({ type: TYPES.SET_LOADING_PATH_DETAIL_STATUS, payload: false })
    }

}



export const getAutocompleteSuggestions = (search) => async dispatch => {
    
    const response = await API.getAutocompleteSuggestions(search);
    
    if (response){
        
        if (response.status === 200){
            
            dispatch({ type: TYPES.GET_AUTO_COMPLETE_SUGGESTIONS, payload: response.data.suggestions })
            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: '' })
        }
        else {
            dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: 'Autocomplete is not functioning right. Try shooting us an email at wiki.crawl.xyz@gmail.com ' })
            
            dispatch({ type: TYPES.SET_LOADING_PATH_DETAIL_STATUS, payload: false })
        }
    }
    else{
    
        dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: 'Something went wrong With loading the autocomplete. Try shooting us an email at wiki.crawl.xyz@gmail.com '  })
        dispatch({ type: TYPES.SET_LOADING_PATH_DETAIL_STATUS, payload: false })
    }
    //dispatch({ type: TYPES.GET_AUTO_COMPLETE_SUGGESTIONS, payload: response.data })
}

export const clearAutocompleteSuggestions = () => async dispatch => {
    dispatch({type: TYPES.CLEAR_AUTO_COMPLETE_SUGGESTIONS, payload: ''})
}

export const fetchRandomLinkRequest = () => ({
    type: TYPES.FETCH_RANDOM_LINK_REQUEST
})

export const fetchRandomLinkSuccess = (data) => ({
    type: TYPES.FETCH_RANDOM_LINK_SUCCESS,
    payload: data
})

export const fetchRandomLinkFailure = (error) => ({
    type: TYPES.FETCH_RANDOM_LINK_FAILURE,
    payload: error
})

export const fetchInitialRandomLink = () => {
    return async (dispatch) => {
        dispatch(fetchRandomLinkRequest());
        try{
            const data = await API.getRandomLink();
            dispatch(fetchRandomLinkSuccess(data));
    } catch(error) {
            dispatch(fetchRandomLinkFailure(error));
        }
    }
}

export const getRandomLinkeghhh = () => async dispatch => {
    const response = await API.getRandomLink();
    dispatch({ type: TYPES.GET_RANDOM_LINK, payload: response })
}

export const setLoadingStatus = (status) => dispatch => {
    //console.log('setting loading to', status)
    dispatch({ type: TYPES.SET_LOADING_STATUS, payload: status })
}

export const setLoadingMoreStatus = (status) => dispatch => {
    //console.log('setting loading more to', status)
    dispatch({ type: TYPES.SET_LOADING_MORE_STATUS, payload: status })
}

export const setLoadingPathDetailStatus = (status) => dispatch => {
    //console.log('setting loading detail to', status)
    
    dispatch({ type: TYPES.SET_LOADING_PATH_DETAIL_STATUS, payload: status })
}

export const setErrorMessage = (message) => dispatch => {
    //console.log('setting error message to', message)
    dispatch({ type: TYPES.SET_ERROR_MESSAGE, payload: message })
}


export const setStartLink = (startLink) => dispatch => {
    dispatch({ type: TYPES.SET_START_LINK, payload: startLink })
}

export const setEndLink = (endLink) => dispatch => {
    dispatch({ type: TYPES.SET_END_LINK, payload: endLink })
}












/*
export function setLoadingStatus(status) {
    return{
        type: TYds
        PES.SET_LOADING_STATUS,
        payload: status
    }
}
*/





