import axios from 'axios';
import * as URL from './urls'

export const testServer = async () => {
    const response = await axios.get(URL.API_ENDPOINT)    
    return response;
}


export const getQueryResults = async (startLink, endLink, direction, resultlength, common, exhaust) => {
    
    const response = await axios.get(URL.API_QUERY_URL, {
        params: {
            startlink: startLink,
            endlink: endLink,
            direction: direction,
            resultlength: resultlength ? resultlength: null,
            forbidden: common,
            exhausted: exhaust,
        }
    }).catch( (error) => {

        return error.response
    })

    return response;
}




 
export const getAutocompleteSuggestions = async (searchTerm) => {    
    const response = await axios.get(URL.API_AUTOCOMPLETE_URL,{
        params: {
            searchterm: searchTerm,
        }
    }).catch( (error) => {
        return error.response
    });
     
    return response;
}

export const getRandomLink = async () => {
    //console.log('huh')
    const response = await axios.get(URL.API_RANDOM_LINK);
    //console.log(response)

    return response.data;
}

export const getPathDetail = async (path) => {
    //console.log(path)
    const response = await axios.post(URL.API_PATH_DETAIL, {
            data: JSON.stringify(path)
    }).catch( (error) => {
        return error.response
    });
    

    
    return response;
}


