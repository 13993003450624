import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as URL from '../app/urls.js';
import CustomLoadable from '../components/Utils/CustomLoadable.js';
import '../App.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Row, Col, Alert } from 'antd';
import {
  setLoadingPathDetailStatus
} from '../actions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';






const LoadableHome = CustomLoadable({
  loader: () => import('../pages/Home.js')
})



const LoadableContact = CustomLoadable({
  loader: () => import('../pages/Contact.js')
})


const LoadableSearch = CustomLoadable({
  loader: () => import('../pages/Search.js')
})

const LoadablePathDetail = CustomLoadable({
  loader: () => import('../pages/PathDetail.js')
})


class App extends Component {

  render() {
      return (
        <div className="App">
          {this.props.errorMessage ? <Alert message={this.props.errorMessage} type="error"/>: null}
          
          
          <BrowserRouter>
            <Header />
            <Row span={24}>
              <Col xs={1} md={1} xxl={6}></Col>
              
              
              <Col xs={22} md={22} xxl={12}>
                <Switch>
                  <Route exact path={URL.HOME} component={LoadableHome} />
                  <Route exact path={URL.CONTACT} component={LoadableContact} />
                  <Route exact path={URL.SEARCH} component={LoadableSearch} />
                  <Route exact path={URL.PATHDETAIL} component={LoadablePathDetail} />
                  <Route exact path="/" component={() => <Redirect to={URL.HOME} />} />
                </Switch>
              </Col>
              <Col xs={1} md={1} xxl={6}></Col>
              
            </Row>
    
            <Footer />
          </BrowserRouter>
        </div>
      );
      
      
    
  }

}

function mapStateToProps(state) {
  return {
      errorMessage: state.errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      setLoadingPathDetailStatus: setLoadingPathDetailStatus
  }, dispatch)
}





export default connect(mapStateToProps, mapDispatchToProps)(App);
